import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dniValidator } from 'src/app/constants/dni-validator';
import { emailPattern } from 'src/app/constants/email-pattern';
import { CommonApiService } from 'src/app/core/common-api.service';
import { ErpPersonService } from 'src/app/core/erp-person.service';
import { CentroItem } from 'src/app/model/centro-item';
import { DocCentTrabResponse } from 'src/app/model/doccenttrab-response';
import { LanguageRequest } from 'src/app/model/language-request';
import { LanguageItem } from 'src/app/model/language-respond';
import { PersonRequest, PersonRequestNewAPI } from 'src/app/model/person-request';
import { TrabajadoreDetItem } from 'src/app/model/trabajadores-respond';
import { CommonUiService } from 'src/app/shared/services/common-ui.service';
import { ConfirmationPopupComponent } from '../../popups/confirmation-popup/confirmation-popup.component';
import moment from 'moment';

@Component({
  selector: 'app-trabajadore-edit',
  templateUrl: './trabajadore-edit.component.html',
  styleUrls: ['./trabajadore-edit.component.scss']
})
export class TrabajadoreEditComponent implements OnInit {
  pageAttrResp: DocCentTrabResponse;
  filterCenters: CentroItem[];
  error: string;
  languageList: LanguageItem[];
  seleCentorList: any = [];
  company: any;
  personRequest: PersonRequest;
  iseliminar: boolean = false;
  chkPassport: boolean = false;
  isLangSelected: boolean = true;
  sex:boolean=false;
  dateOfBirth:string

  @Input() public trabajador: TrabajadoreDetItem;
  @Input() public status: string;  
  @Output() isSaved: EventEmitter<any> = new EventEmitter();

  genderList = [
    { gid: "H", name: 'Hombre' },
    { gid: "M", name: 'Mujer' }
  ];

  personaForm = new FormGroup({
    nombre: new FormControl(null, [Validators.required]),
    apellidos: new FormControl(null, [Validators.required]),
    nip: new FormControl(null, [dniValidator()]),
    tel1: new FormControl(null, [Validators.minLength(9), Validators.maxLength(12)]),
    tel2: new FormControl(null, [Validators.minLength(9), Validators.maxLength(12)]),
    fecha_nacimiento: new FormControl(null, Validators.required),
    email2: new FormControl(null,[Validators.pattern(emailPattern())]),
    email1: new FormControl(null,[Validators.pattern(emailPattern())]),
    genero: new FormControl(null, Validators.required),
    language_id: new FormControl(null, Validators.required),
    comentarios: new FormControl(null),
    empresa_id: new FormControl(null, [Validators.required]),
    centro_trabajo: new FormControl(null),
    id: new FormControl(0),
    id_trabajador: new FormControl(null),
  });
  
  constructor(public modal: NgbActiveModal,
    private _erpPersonService: ErpPersonService,
    public _commonUiService: CommonUiService,
    private modalService: NgbModal,
    private _commonApiService: CommonApiService) { 
      this.company = JSON.parse(localStorage.getItem('company')); 
    }

  ngOnInit() {
    this.getPageAttributes();
    this.getLanguageList();
    this.personaForm.controls.empresa_id.setValue(this.company.empresa_txt);    

    if(this.status == 'edit') {
      if(this.company.userType == "tech") {
        this.iseliminar = true;
      } else {
        this.iseliminar = false;
      }
      this.getPersonData();
    }
  }

  get personaFormControl() {
    return this.personaForm.controls;
  }

  getPersonData() {
    const requestData = {
      trabajador_id: this.trabajador.trabajador_id, //23806014
    }
    let genero = '';
    this._erpPersonService.getPersonList(requestData).subscribe(res => {
      if (res) {
        this.personRequest = res.data;
        this.personaForm.controls.id.setValue(res.data.id);
        this.personaForm.controls.id_trabajador.setValue(res.data.id_trabajador);
        this.personaForm.controls.apellidos.setValue(res.data.apellidos?.trim());
        this.personaForm.controls.nombre.setValue(res.data.nombre.trim());
        this.personaForm.controls.nip.setValue(res.data.nif.trim());
        this.personaForm.controls.email1.setValue(res.data.email1?.trim());
        this.personaForm.controls.email2.setValue(res.data.email2?.trim());
        this.personaForm.controls.tel1.setValue(res.data.tel1?.trim());
        this.personaForm.controls.tel2.setValue(res.data.tel2?.trim());
        this.personaForm.controls.fecha_nacimiento.setValue(res.data.fecha_nacimiento);
        this.personaForm.controls.language_id.setValue(res.data.id_idioma);
        if(res.data.genero == 'True'){
          genero = 'H'
        }else if(res.data.genero == 'False'){
          genero = 'M'
        }
        this.personaForm.controls.genero.setValue(genero);
        this.personaForm.controls.comentarios.setValue(res.data.comentarios?.trim());

        let centroList = res.data.centros;

        if (centroList && centroList.length > 0) {
          centroList.forEach(item => {
            let centros =  this.filterCenters.filter(x => x.id == item.id_centro);
            if(centros.length > 0) {
              this.seleCentorList.push({id: item.id_centro , nombre_centro: centros[0].nombre_centro});
            }            
          });
        }
      }
    }, error => {
      console.log(error);
    });
  }

  getPageAttributes(): void {
    var retrievedObject = localStorage.getItem('centrosSource');
    this.pageAttrResp = JSON.parse(retrievedObject);
    if (this.pageAttrResp != null) {
      if (this.pageAttrResp.webError != null) {
        this.error = this.pageAttrResp.webError.messageToUser;
      } else {
        this.filterCenters = this.pageAttrResp.data.centros;
      }       
    }     
  }

  getLanguageList() {
    const requestData = new LanguageRequest();
    requestData.type = null;
    return this._commonApiService.getLanguages(requestData).subscribe(res => {
      if (res) {        
        this.languageList = res.data;
      }
    });
  }  

  addCentrotrabajo() {    
    let centro_trabajo = this.personaForm.controls.centro_trabajo.value;
    const centros = this.filterCenters.filter(x => x.id == centro_trabajo);
    if (centros.length > 0) {
      if ((this.seleCentorList.filter(x => x.id == centro_trabajo)).length == 0) {
        this.seleCentorList.push({id: centros[0].id , nombre_centro: centros[0].nombre_centro});
      }      
    }
  }

  removeCentrotrabajo(item: any) {
    const newList = this.seleCentorList.filter(x => x.id != item.id);
    this.seleCentorList = newList;
  }

  savePerson() {
    if (this.personaForm.valid && this.seleCentorList.length > 0) {
      let savepersondata = this.updatePersonData(0);
      this._erpPersonService.updatePersonList(savepersondata).subscribe(res => {
        if (res && res.webError == null) {
          this._commonUiService.showSuccessToast("Guardado exitosamente !!!");
          this.isSaved.emit(true);
          this.modal.dismiss();
        } else {
          this._commonUiService.showWarningMessage(res.webError.messageToUser);
        }
      });
    }else if(this.personaForm.invalid){
      this.personaForm.markAllAsTouched();
    }
  }  

  updatePersonData(eliminado: number) {
    let trabajadorList = [];
    let emprasaList = [];
    let centroList = [];

    const email1 = this.personRequest ? this.personRequest.email1?.trim() : null;
    const tel1 = this.personRequest ? this.personRequest.tel1?.trim() : null;
    const id_idioma = this.personRequest ? this.personRequest.id_idioma : null;
    
    //Language Set When Editing
    if(this.personaForm.controls.language_id.value==null)
    {
      this.personaForm.controls.language_id.setValue(id_idioma);
    }

    this.seleCentorList.forEach(item => {
      let trabajador = {
        id_centro_trabajo: item.id,
        empresa_sede_id: 0,
        empresa_sede_txt: item.nombre_centro,
        empresa_sede_principal: true
      };

      let centro={
          id_centro: item.id,
          nombre_centro: item.nombre_centro
      }

      centroList.push(centro)
      trabajadorList.push(trabajador);

    });

    let emprasa = {
      id: 0,
      id_empresa: this.company.empresa_id,
      sedes: trabajadorList
    };

    emprasaList.push(emprasa);

    //Set DOB 
    const fechanacimiento = this.personaForm.get('fecha_nacimiento').value;
    if (moment.isMoment(fechanacimiento)) {
      this.dateOfBirth = fechanacimiento.format('YYYYMMDD'); 
    } else {
      this.dateOfBirth = fechanacimiento.toString();
    }

    if(this.personaForm.controls.comentarios.value?.trim()=='H'){
      this.sex=true
    }else{
      this.sex=false
    }

    let persondata: PersonRequestNewAPI = {
      id_empresa:this.company.empresa_id,
      id_trabajador: this.personaForm.controls.id_trabajador.value,
      email2: this.personaForm.controls.email2.value?.trim(),
      apellidos: this.personaForm.controls.apellidos.value?.trim(),
      nombre: this.personaForm.controls.nombre.value.trim(),
      nif: this.personaForm.controls.nip.value.trim(),
      tel2: this.personaForm.controls.tel2.value?.trim(),
      fecha_nacimiento: this.dateOfBirth,
      genero: this.sex,
      comentarios: this.personaForm.controls.comentarios.value?.trim(),
      notas: this.personaForm.controls.comentarios.value?.trim(),
      email1: this.personaForm.controls.email1.value?.trim(),
      tel1: this.personaForm.controls.tel1.value?.trim(),
      id_idioma: this.personaForm.controls.language_id.value,
      centros: centroList
    }
    return persondata;
  }

  onChangeChkPassport($event) {
    this.chkPassport = $event.target.checked;
    if (this.chkPassport) {
      if (this.personaForm.controls.nip.value != '') {
        this.personaForm.controls.nip.clearValidators();
        this.personaForm.controls.nip.updateValueAndValidity();
      } else {
        this.personaForm.controls['nip'].setErrors({ invalid: true });
      }
    } else if (!this.chkPassport) {
      this.personaForm.controls.nip.setValidators(dniValidator());
      this.personaForm.controls.nip.updateValueAndValidity();
    }
  }

  omitSpecialChar(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  langChange(event) {
    if (!event) {
      this.isLangSelected = false;
      this.personaForm.updateValueAndValidity();
    } else {
      this.isLangSelected = true;
      this.personaForm.updateValueAndValidity();
    }
  }

  deletePerson() {
    const modalRef = this.modalService.open(ConfirmationPopupComponent, { centered: true });
    modalRef.componentInstance.confirmationMessage = '¿Está seguro que quiere eliminar este trabajador?';
    modalRef.componentInstance.messageBody = "Si lo elimina, solo podrá ser recuperado por soporte.";
    modalRef.result.then(close => {
      if (close) {
        this.deletePersonAPICall();
      }
    });
  }

  deletePersonAPICall() {
    let deletepersondata = this.updatePersonData(1);

    const deleteRequest = {
      id_trabajador: deletepersondata.id_trabajador
    }
    this._erpPersonService.deletePerson(deleteRequest).subscribe(res => {
      if (res) {
          this._commonUiService.showSuccessToast("borrado exitosamente !!!");
        this.isSaved.emit(true);
        this.modal.dismiss();
      }
    });
  }

}
